import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/politika',
    name: 'Politika',
    component: () =>
      import(/* webpackChunkName: "Politika" */ '../views/Politika.vue')
  },
  {
    path: '/items',
    name: 'Items',
    component: () =>
      import(/* webpackChunkName: "items" */ '../views/Items.vue'),
    children: [
      {
        path: 'voucher',
        component: () =>
          import(
            /* webpackChunkName: "voucher" */ '../components/items/voucher/Voucher.vue'
          ),
        children: [
          {
            path: 'pipes',
            component: () =>
              import(
                /* webpackChunkName: "pipes" */ '../components/items/voucher/nested/Pipes.vue'
              )
          },
          {
            path: 'gutter',
            component: () =>
              import(
                /* webpackChunkName: "gutter" */ '../components/items/voucher/nested/Gutter.vue'
              )
          },
          {
            path: 'coverage',
            component: () =>
              import(
                /* webpackChunkName: "coverage" */ '../components/items/voucher/nested/Coverage.vue'
              )
          },
          {
            path: 'others',
            component: () =>
              import(
                /* webpackChunkName: "others" */ '../components/items/voucher/nested/Others.vue'
              )
          }
        ]
      },
      {
        path: 'crocheus',
        component: () =>
          import(
            /* webpackChunkName: "crocheus" */ '../components/items/crocheus/Crocheus.vue'
          )
      },
      {
        path: 'sale',
        component: () =>
          import(
            /* webpackChunkName: "crocheus" */ '../components/items/sale/Sale.vue'
          )
      },
      {
        path: 'products-from-tin',
        component: () =>
          import(
            /* webpackChunkName: "productsFromTin" */ '../components/items/productsFromTin/ProductsFromTin.vue'
          )
      },
      {
        path: 'crowning-cornice',
        component: () =>
          import(
            /* webpackChunkName: "crowningCornice" */ '../components/items/crowningCornice/CrowningCornice.vue'
          )
      }
    ]
  },
  {
    path: '/services',
    name: 'Services',
    component: () =>
      import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },
  {
    path: '/arbo',
    name: 'Arbo',
    component: () =>
      import(/* webpackChunkName: "arbo" */ '../components/services/Arbo.vue')
  },
  {
    path: '/decoration',
    name: 'Decoration',
    component: () =>
      import(
        /* webpackChunkName: "arbo" */ '../components/services/Decoration.vue'
      )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
